// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";

Rails.start();
ActiveStorage.start();
document.addEventListener("DOMContentLoaded", function () {
  $(".ebv-daterangepicker").each(function (index, element) {
    $(element).daterangepicker({
      timePicker: false,
      opens: "left",
      startDate: $(element).data("from-date"),
      endDate: $(element).data("to-date"),
      locale: {
        format: "YYYY-MM-DD",
      },
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  // Datatables Responsive
  $(".ebv-datatable").DataTable({
    pageLength: 100,
    lengthChange: false,
  });

  $("table tbody td.colorize-items-order").colorize({
    parse: function (e) {
      return $(e).attr("data-colorizevalue");
    },
  });

  $("table tbody td.colorize-net-rev").colorize({
    parse: function (e) {
      return $(e).attr("data-colorizevalue");
    },
  });

  $("table tbody td.colorize-gross-rev").colorize({
    parse: function (e) {
      return $(e).attr("data-colorizevalue");
    },
  });

  $("table tbody td.colorize-sales-margin").colorize({
    parse: function (e) {
      return $(e).attr("data-colorizevalue");
    },
  });
  $("a.kpis_compared_row_link").click(function (e) {
    e.preventDefault();
    $(".kpis_compared_row").removeClass("d-inline").addClass("d-none");
    $($(e.currentTarget).data("target"))
      .removeClass("d-none")
      .addClass("d-inline");
  });
});

document.addEventListener("DOMContentLoaded", function () {
  $(".standard-line-chart").each(function (index, element) {
    var cCanvas = $(element);
    new Chart(cCanvas, {
      type: cCanvas.data("chart-type"),
      data: {
        labels: cCanvas.data("chart-labels"),
        datasets: [
          {
            label: cCanvas.data("chart-label"),
            fill: false,
            backgroundColor: "transparent",
            borderColor: window.theme.primary,
            data: cCanvas.data("chart-numbers"),
          },
        ],
      },
      options: {
        tooltips: {
          intersect: false,
        },
        hover: {
          intersect: true,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              stacked: false,
              ticks: {
                stepSize: cCanvas.data("chart-step"),
                min: cCanvas.data("chart-min"),
                max: cCanvas.data("chart-max"),
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              gridLines: {
                color: "transparent",
              },
            },
          ],
        },
      },
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  $(".standard-pie-chart").each(function (index, element) {
    var cCanvas = $(element);
    new Chart(cCanvas, {
      type: cCanvas.data("chart-type"),
      data: {
        labels: cCanvas.data("chart-labels"),
        datasets: [
          {
            backgroundColor: [
              window.theme.primary,
              window.theme.warning,
              window.theme.danger,
              "#E8EAED",
            ],
            borderColor: "transparent",
            data: cCanvas.data("chart-numbers"),
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
        },
        maintainAspectRatio: false,
      },
    });
  });
});
